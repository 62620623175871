// import { Navigate, Outlet } from "react-router-dom";

// const useAuth = () => {
//   const auth = sessionStorage.getItem("Token");

//    //console.log('sadsadsa',auth)
  
//   //const accessToken = auth?.data?.accessToken;
//   const accessToken = auth;

  
//   if (!!accessToken) {
//     return true;
//   } else if (
//     accessToken === null ||
//     accessToken === undefined
//   ) {
//     return true;
//   }
// };

// const ProtectedRoutes = () => {
//   const auth = useAuth();
//   return auth ? <Outlet /> : <Navigate to='/' />;
// };

// export default ProtectedRoutes;
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const auth = sessionStorage.getItem("Token");

  // Return `true` if the token exists, otherwise return `false`
  if (auth) {
    return true;  // Authenticated
  } else {
    return false;  // Not authenticated
  }
};

const ProtectedRoutes = () => {
  const auth = useAuth();
  
  // If authenticated, render the child routes (`<Outlet />`); otherwise, navigate to login page
  return auth ? <Outlet /> : <Navigate to='/' />;
};

export default ProtectedRoutes;
