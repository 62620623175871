export const PATHS = {
  login: "/",
 
  User:'/users',
  UsersEdit:'/userEdit',
  Dashboard: "/dashboard",
  IdentityRecords:"/identityrecords",
  IdentityRecordsView:"/identityrecordsview",
  Auditdashboard:"/auditdashboard",
  Roles:"/roles",
  userManagement: {
    root: "/userManagement",
  },
  Audit: "/audit",
  Management: {
    root: "/management",
    pending:"/pending",
    completed:"/completed"
  },
 
  ManagementView:"managementView",

 
};

export const SM_WIDTH = 1200;


