import {createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../mappers/app';

const appState: AppState = {
    alert: {type: '', message: ''},
    showAlert: false,
    loading: false,
    reload: false,
};


const AppSlice = createSlice({
    name: 'app',
    initialState: appState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        reload(state) {
            state.reload = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        showAlert(state, {payload}) {
            state.showAlert = true;
            state.alert.type = payload.type;
            state.alert.message = payload.message;
        },
        hideAlert(state) {
            state.showAlert = false;
            state.alert.type = '';
            state.alert.message = '';
        },
        setLoading(state,{payload}){
            state.value = payload
        }
    },
});

export const {
    startLoading,
    stopLoading,
    hideAlert,
    showAlert,
    reload,
    setLoading
} = AppSlice.actions;
export default AppSlice.reducer;
